body {
    margin: 0;
    overflow: unset !important;
}
#root {
    overflow: hidden !important;
}
.CrossfadeImage {
    position: absolute !important;
    top: 125px !important;
    height: 500px !important;
    max-height: none !important;
}

.mobileHeroImage {
    max-width: 600px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.mobileHeroImage-scrolled {
    max-width: 600px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

@media (max-width: 660px) {
    .mobileHeroImage-scrolled{
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}
