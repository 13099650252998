.large-slider div {
    height: 100% !important;
}

.large-slider .text-wrapper div {
    height: auto !important;
}

.large-slider .slick-dots {
    position: static !important;
}

.large-slider .slick-dots li button {
    width: 12px !important;
    height: 12px !important;
}

.large-slider .slick-dots li button::before {
    border-color: #FFFFFF;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 12px !important;
    height: 12px !important;
    font-size: 17px !important;
    color: rgba(0,0,0,0) !important;
}

.large-slider .slick-dots .slick-active button::before {
    color: #FFFFFF !important;
    border-width: 0;
    font-size: 14px !important;
    padding-bottom: 2px !important;
    margin-top: -3px;
}

.large-slider .slick-dots {
    padding-top: 10px !important;
}

.large-slider .slick-dots li button::before {
    border-color: #6a5b5d;
}

.large-slider .slick-dots .slick-active button::before {
    color: #6a5b5d !important;
}

