.articlePage.dropCap #paragraph p:first-of-type:first-letter {
    position: relative;
    float: left;
    display: block;
    font-size: 122px;
    line-height: 95px;
    font-weight: 300;
    padding-top: 11px;
    padding-right: 15px;
    padding-left: 0;
    color: #D07D3C;
    font-family: "Neutraface 2 Text";
    text-transform: uppercase;
}
.articlePage {
    position: relative;
}
.increaseWidth {
    width: 100%;
    display: flex;
    max-width: 1340px;
}

.increaseWidth > div {
    width: 100%;
}

iframe.page-entry {
    position:absolute;
    top:0;
    left:50%;
    transform:translateX(-50%);
    width:75%;
    height:100%;
}
div.page-entry {
    padding:45% 0 0 0;
    position:relative;
}
@media (max-width: 768px) {
    iframe.page-entry {
        width: 100% !important;
        left: 0 !important;
        transform: none !important;
    }
    div.page-entry {
        padding:56% 0 0 0 !important;
    }
}

.articlePage.increaseWidth > div > div {
    width: 100%;
}

.articlePage p, #paragraph {
    color: #6A5B5D;
    font-weight: 400;
    font-size: 18px;
    font-family: "Neutraface 2 Text";
    line-height: 1.5;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1.3 !important;
    font-family: 'Neutraface 2 Text';
    /*margin-top: 30px !important;*/
    margin-bottom: 15px !important;
}
h1 {
    font-weight: 300 !important;
    font-size: 40px !important;
    color: #6A5B5D;
    margin-top: 40px !important;
}
h2 {
    font-weight: 300 !important;
    font-size: 32px !important;
    color: #65A3BE;
    text-transform: uppercase;
    letter-spacing: 3.4px;
    margin-top: 40px !important;
}
h3 {
    font-weight: 500 !important;
    font-size: 22px !important;
    text-transform: uppercase;
    color: #65A3BE;
    letter-spacing: 2.4px;
}
h4 {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 18px !important;
    color: #A39A93;
    letter-spacing: 1.8px;
    padding-top: 7px;
}
h5 {
    font-style: italic;
    font-weight: 500;
    font-size: 18px !important;
    color: #A39A93;
    letter-spacing: 0.55px;
    font-family: "Neutraface Slab Text";
}
h6 {
    font-weight: 600;
    font-size: 18px !important;
    color: #6A5B5D;
}
blockquote {
    color: #CE7D43;
    padding-left: 30px;
    font-size: 36px;
    font-weight: 300;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    font-family: "Neutraface 2 Text";
    border-left-style: solid;
    border-left-width: 4px;
    border-left-color: #CE7D43;
    letter-spacing: 1.07px;
    text-transform: uppercase;
    line-height: 1.35;
    padding-top: 6px;
}
.articleAnchor, .articleAnchor:link {
    color: #65A3BE !important;
    font-family: "Neutraface 2 Text";
    font-size: 18px;
    font-weight: 400;
}
.articleUl {
    padding-inline-start: 32px;
    overflow: hidden;
}
.articleUl li {
    color: #6A5B5D;
    font-size: 18px !important;
    list-style: none;
    position: relative;
    font-family: "Neutraface 2 Text";
    line-height: 1.5;
}
.articleUl li::before {
    color: #CF7D43;
    content: "\2022";
    display: inline-block;
    font-size: 40px;
    left: -32px;
    position: absolute;
    top: -20px;
}
.articleOl {
    counter-reset: li;
    padding-inline-start: 65px;
}
.articleOl li {
    color: #6A5B5D;
    font-size: 18px !important;
    font-family: "Neutraface 2 Text";
    position: relative;
    line-height: 1.5;
    margin-bottom: 15px;
}
.articleOl li::before {
    content: counter(li) '.';
    counter-increment: li;
    color: #CF7D43;
    font-family: "Neutraface 2 Text";
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-right: 1em;
    letter-spacing: 1px;
    left: -65px;
    position: absolute;
}

@media (max-width: 768px) {
    /*p.dropCap:first-letter {*/
    /*    font-size: 100px;*/
    /*    line-height: 80px;*/
    /*}*/
    /*.articlePage p {*/
    /*    font-size: 16px !important;*/
    /*}*/
    /*h1 {*/
    /*    font-size: 46px !important;*/
    /*}*/
    /*h2 {*/
    /*    font-size: 42px !important;*/
    /*}*/
    /*h3 {*/
    /*    font-size: 32px !important;*/
    /*}*/
    /*h4 {*/
    /*    font-size: 21px !important;*/
    /*}*/
    /*h5 {*/
    /*    font-size: 16px !important;*/
    /*}*/
    /*h6 {*/
    /*    font-size: 16px !important;*/
    /*}*/
    /*blockquote {*/
    /*    font-size: 32px !important;*/
    /*}*/
    /*.articleAnchor, .articleAnchor:link {*/
    /*    font-size: 16px;*/
    /*}*/
    /*.articleUl li {*/
    /*    font-size: 16px !important;*/
    /*}*/
    /*.articleOl li {*/
    /*    font-size: 16px !important;*/
    /*}*/
}

.whoIsJesus #paragraph p{
    color: #F8F5EE !important;
}

