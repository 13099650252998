.__react_component_tooltip {
    box-shadow: 0 3px 30px #00000029 !important;
    font-family: "Neutraface 2 Text" !important;
}
.__react_component_tooltip.show {
    opacity: 1 !important;
    border: 0 !important;
    padding: 0 !important;
    background-color: #FFF !important;
    z-index: 10000 !important;
}
@media (max-width: 768px) {
    .__react_component_tooltip {
        top: auto !important;
        right: 40px !important;
        left: 40px !important;
        bottom: 40px !important;
        width: auto !important;
        margin: 0 !important;
    }
}
.tooltip {
    pointer-events: auto !important;
}
.tooltip:hover {
    visibility: visible !important;
    opacity: 1 !important;
}
svg.rsm-svg {
    outline: none !important;
}
svg#marker {
    overflow: visible;
    width: 100%;
    cursor: pointer;
}
.controls {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 1.25rem 0;
    text-align: center;
    transform: translateX(-50%);
}
.controls > button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    width: 2rem;
    background: #333;
    color: #fff;
    border-radius: 100%;
    border: 0;
}
.controls > button:first-child {
    margin-right: 15px;
}
