#introSlider .sliderWrapper, #introSlider .slick-slide.slick-slide-active {
    height: 450px !important;
    padding-left: 7.5px !important;
    padding-right: 7.5px !important;
    width: calc(100vw - 150px - 15px) !important;
}
#introSlider .slick-slide.slick-active div {
    display: flex !important;
    justify-content: flex-start;
}
#introSlider .slick-slide.slick-active.slick-current div {
    display: flex !important;
    width: 100%;
    justify-content: flex-end;
}
#introSlider .slick-dots {
    position: static !important;
}
#introSlider .slick-dots li button {
    width: 12px !important;
    height: 12px !important;
}
#introSlider .slick-dots li button::before {
    border-color: #FFFFFF;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 12px !important;
    height: 12px !important;
    font-size: 17px !important;
    color: rgba(0,0,0,0) !important;
}
#introSlider .slick-dots .slick-active button::before {
    color: #FFFFFF !important;
    border-width: 0;
    font-size: 14px !important;
    padding-bottom: 2px !important;
    margin-top: -3px;
}
@media (min-width: 750px) {
    #introSlider .sliderWrapper {
        padding: 0 !important;
        position: absolute;
        height: 100% !important;
        width: 100% !important;
    }
    #introSlider .slick-dots {
        padding-top: 10px !important;
    }
    #introSlider .slick-dots li button::before {
        border-color: #6a5b5d;
    }
    #introSlider .slick-dots .slick-active button::before {
        color: #6a5b5d !important;
    }
}
@media (max-width: 750px) {
    #introSlider .sliderWrapper, .slick-slide.slick-slide-active {
        height: 540px !important;
    }
}
@media (max-width: 730px) {
    #introSlider .sliderWrapper, .slick-slide.slick-slide-active {
        width: calc(100vw - 60px - 15px) !important;
        height: 340px !important;
    }
    #introSlider .slick-list {
        height: 348px !important;
    }
}
@media (max-width: 530px) {
    #introSlider .slick-slide.slick-active div {
        display: flex !important;
        justify-content: flex-start;
    }
    #introSlider .slick-slide.slick-active.slick-current div {
        display: flex !important;
        justify-content: flex-end;
    }
}
@media (max-width: 414px) {
    #introSlider .sliderWrapper, #introSlider .slick-slide.slick-slide-active {
        height: 325px !important;
    }
    #introSlider .slick-list {
        height: 333px !important;
    }
}
@media (max-width: 375px) {
    #introSlider .sliderWrapper, #introSlider .slick-slide.slick-slide-active {
        height: 275px !important;
    }
    #introSlider .slick-list {
        height: 283px !important;
    }
}
@media (max-width: 320px) {
    #introSlider .sliderWrapper, #introSlider .slick-slide.slick-slide-active {
        height: 220px !important;
    }
    #introSlider .slick-list {
        height: 228px !important;
    }
}

@media (max-width: 768px) {
    .parallax-outer.parallax-inner div{
        margin-left: 35px !important;
        margin-right: 35px !important;
    }
}
