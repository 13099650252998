@font-face {
    font-family: 'Neutraface 2 Text';
    font-style: normal;
    font-weight: 400;
    src: local('Neutraface 2 Text'), url('./Neutra2Text-Book.otf')
}

@font-face {
    font-family: 'Neutraface 2 Text';
    font-style: italic;
    font-weight: 400;
    src: local('Neutraface 2 Text'), url('./Neutra2Text-BookItalic.otf')
}

@font-face {
    font-family: 'Neutraface 2 Text';
    font-style: normal;
    font-weight: 600;
    src: local('Neutraface 2 Text'), url('./Neutra2Text-Bold.otf')
}

@font-face {
    font-family: 'Neutraface 2 Text';
    font-style: italic;
    font-weight: 600;
    src: local('Neutraface 2 Text'), url('./Neutra2Text-BoldItalic.otf')
}

@font-face {
    font-family: 'Neutraface 2 Text';
    font-style: normal;
    font-weight: 500;
    src: local('Neutraface 2 Text'), url('./Neutra2Text-Demi.otf')
}

@font-face {
    font-family: 'Neutraface 2 Text';
    font-style: italic;
    font-weight: 500;
    src: local('Neutraface 2 Text'), url('./Neutra2Text-DemiItalic.otf')
}

@font-face {
    font-family: 'Neutraface 2 Text';
    font-style: normal;
    font-weight: 300;
    src: local('Neutraface 2 Text'), url('./Neutra2Text-Light.otf')
}

@font-face {
    font-family: 'Neutraface 2 Text';
    font-style: italic;
    font-weight: 300;
    src: local('Neutraface 2 Text'), url('./Neutra2Text-LightItalic.otf')
}
@font-face {
    font-family: 'Neutraface Slab Text';
    font-style: normal;
    font-weight: 400;
    src: local('Neutraface Slab Text'), url('./NeutrafaceSlabText-Book.otf')
}
@font-face {
    font-family: 'Neutraface Slab Text';
    font-style: italic;
    font-weight: 400;
    src: local('Neutraface Slab Text'), url('./NeutrafaceSlabText-BookItalic.otf')
}
@font-face {
    font-family: 'Neutraface Slab Text';
    font-style: normal;
    font-weight: 300;
    src: local('Neutraface Slab Text'), url('./NeutrafaceSlabText-Light.otf')
}
body {
    font-family: 'Neutraface 2 Text' !important;
}
